import React from "react";
import { render } from "react-dom";
import { Router, Link } from "@gatsbyjs/reach-router";
import Home from "./Home";
import Privacy from "./Privacy";
import Paper from "./Paper";
import Diseases from "./Diseases";
import BlackPodLevels from "./BlackPodLevels";
import "mapbox-gl/dist/mapbox-gl.css";
import "./assets/css/styles.css";

const App = () => {
  return (
    <Router>
      <Home path="/" />
      <Privacy path="/privacy/" />
      <Diseases path="/diseases/" />
      <BlackPodLevels path="/black-pod-levels/" />
      <Paper path="/paper" />
    </Router>
  );
};

render(React.createElement(App), document.getElementById("root"));
